import type { NextPage } from "next";
import type { DefaultLayoutData, XXLAppData } from "../global";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { getFullPageTitle } from "../utils/page-helper";
import Head from "next/head";
import type { GetServerSidePropsCallback } from "../utils/layout/with-layout-page-props";
import { withPageData } from "../utils/common-page-data/common-page-data";
import { ErrorPageContent } from "../components/ErrorPage/ErrorPage";

type ErrorPageProps = {
  siteId: string;
};
const ErrorPage: NextPage<XXLAppData & DefaultLayoutData> = () => {
  const { t } = useTranslations();
  const pageTitle = getFullPageTitle(t("errors.not.found.title"), t);

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="robots" content={"noindex"} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:type" content="website" />
      </Head>
      <ErrorPageContent />
    </>
  );
};

export const getErrorPageProps: GetServerSidePropsCallback<
  ErrorPageProps & XXLAppData
> = async (_context, props) =>
  Promise.resolve({
    props: { ...props, siteId: props.environmentData.siteUid },
  });

export default ErrorPage;

export const getServerSideProps = withPageData(getErrorPageProps);
